@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #10277c;
  --secondary-color: #28439d;
  --base-font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
}

.shadow-icon {
  border-radius: 50%;
  box-shadow: 0px 4px 8px #0000001f;
}

.achievements > div {
  height: 85px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e6e7e9;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-box {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 800;
  background: linear-gradient(
    89.76deg,
    #28439d 4.24%,
    rgba(56, 85, 179, 0.8) 69.76%
  );
}


.progress-bar {
  height: 100%;
  font-size: 10px;
  color: white;
  text-align: center;
  border-radius: 0.5rem;
  background: linear-gradient(270deg, #60b0fa 0%, #3855b3 100%);
}

.graph-key > div {
  position: relative;

  .color {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    left: -20px;
    top: 7px;
  }
}

.graph-key .icon-button {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  font-size: 14px;
}

.dialog label {
  font-weight: 700;
  font-size: 14px;
  color: #181819b2;
}

.category-preview {
  width: 120px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid var(--secondary-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 500;

  i {
    font-size: 30px;
    color: var(--secondary-color);
    opacity: 0.8;
  }

  img {
    max-width: 30px;
    max-height: 30px;
  }

  p {
    max-width: 90px;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis;
  }
}

.MuiDialogActions-root {
  padding: 20px 30px !important;
}
.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-nunito {
  font-family: 'Nunito', sans-serif;
}

.font-tilt-warp {
  font-family: 'Tilt Warp', sans-serif;
}

.font-plus-jakarta-sans {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}