/* HeroComponent.css */
.animate-slide-in {
    animation: slide-in 4s ease-out;
}

@keyframes slide-in {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}