.categoryy-paragrh{
    width: 30px;
    height: 30px;
    background-color: #EAEAFD;
    border-radius: 50px;
    font-size: 22px;
    color:#3855B3;
}

.section-container {
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .imgg {
    width: 100%;
    height: 350px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .userImgg {
    display: flex;
    flex-direction: row;
    width: 100px;
    height: 100px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  }
  
  .UserHeadingg {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  
  .userInfoo {
    margin-left: 17%;
    margin-top: 1%;
    color: #3855B3;  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .userText {
    display: flex;
    flex-direction: column;
  }
  
  .buttonContainer {
    margin-left: auto;
  }
  
  .userDescription {
    color: #353535;  
    background-color: #28439D1A;
    margin-top: 3%;
    margin-left: 9%;
    width: 80%;
    height: 120px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
      
      
  }
  
  body {
    margin: 0;
    background-color: #f6f7fa;
  }
  
  .social-iconss {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
  }
  
  .social-iconss .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #28439D1A;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 10px;
    width: 40px;  /* Adjust as needed */
    height: 40px; /* Adjust as needed */
  }
  
  .social-iconss img {
    width: 30px;
    height: 30px;
  }
  
  .btn-div {
    margin-top: 3%;
    margin-left: 15%;
    text-align: center;
  }
  
  .b1 {
    background-color: #3855B3;
    width: 60%;
    height: 50px;
    border-radius: 50px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .b-icon {
    position:absolute;
    margin-right: 70%;
  }
  
  .text {
    display: inline-block;
    vertical-align: middle;
  }
  
  .linklead-p {
    margin-left: 42%;
    margin-top: 5%;
  }
  
  @media only screen and (max-width: 768px) {
    .userImgg {
      top: 330px;
      left: 25%; 
    }
    .userInfoo {
      margin-left: 40%;
    }
    .userDescc {
      margin-left: 0;
      width: 80%;
    }
    .btn-div {
      margin-left: 30%; 
      margin-top: 5%;
    }
    .linklead-p {
      margin-left: 0;
      text-align: center;
    }
    .social-iconss{
      margin-top: 5%;
    }
  }
  