.section-container {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.iimggggg {
  width: 100%;
  height: 800px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.uuserImggggg {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 20%;
  border: 3px solid #ffffff; /* Adjust this color to match your background */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
}

.UUserHeadinggggg {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #3855b3;
}
.uuserParagraph {
  position: absolute;
  top: 330px;
  left: 50%;
  transform: translateX(-50%);
  margin-right: 5%;
  color: #3855b3;
}

.uuserInfooooo {
  margin-top: 1%;
  color: white;
}

.uuserDesccc {
  position: absolute;
  top: 355px;
  left: 50%;
  transform: translateX(-50%);
  color: #3855b3;
}
body {
  margin: 0;
  background-color: #f6f7fa;
}


.bb1111,
.bb2222,
.bb3333 {
  background-color: rgb(247, 247, 247);
  height: 50px;
  border-radius: 50px;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgb(197, 195, 195);
  color: #3855b3;
  text-decoration: none;
  font-size: 18px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the shadow properties as needed */
}

.b-icon {
  position: absolute;
  margin-right: 70%;
}

.text {
  display: inline-block;
  vertical-align: middle;
}



.ssocial-iconsssss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ssocial-iconsssss img {
  margin: 0 15px;
  width: 30px;
  height: 30px;
}
