.section-cont {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.imgTemplate7 {
  width: 100%;
  height: 700px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover; /* Ensure the image maintains its aspect ratio */

}

.imgTemplate71 {
  width: calc(100% - 40px);
  height: 570px;
  position: absolute;
  top: 22%;
  left: 20px;
  border-top-left-radius: 50% 20%;
  border-top-right-radius: 50% 20%;
}

@media (min-width: 1024px) {
  .imgTemplate71 {
    width: calc(100% - 40px);
    left: 20px;
  }
}
.userImgtemplate7 {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #ffffff; 
}

.UserHeadingTemplate7 {
  position: absolute;
  top: 230px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.userParagraphTemplate7 {
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translateX(-50%);
}

.userInfoooooo {
  font-family: Roboto Serif;
  margin-top: 1%;
  color: #ffffff;
}
body {
  margin: 0;
  background-color: #f6f7fa;
}

.button-divvvvvv {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 3%;
  text-align: center;
  width: 60%;
}

.button11111,
.button22222,
.button33333,
.b444 {
  background-color: rgb(255, 255, 255);
  height: 50px;
  font-family: Ribeye Marrow;
  border-radius: 50px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  color: #3855b3;
  text-decoration: none;
  font-size: 18px;
}

.b-icon {
  position: absolute;
  margin-right: 70%;
}

.text {
  display: inline-block;
  vertical-align: middle;
}

.linkleadai-pppppp {
  position: absolute;
  top: 660px;
  left: 50%;
  transform: translateX(-50%);
}
.social-iconsTemplate7 {
  position: absolute;
  top: 570px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-iconsTemplate7 img {
  margin: 0 15px;
  width: 30px;
  height: 30px;
}
@media only screen and (max-width: 768px) {
  .userImgTemplate7 {
    top: 150px;
    left: 50%;

  }
  .imgTemplate71 {
    left: 20px;
    width: 89%;
    border-top-left-radius: 30% 10%;
    border-top-right-radius: 30% 10%;
  }
    .imgTemplate7 {
height: auto;
  }
  .UserHeadingTemplate7 {
    left: 50%;
  }
  .userParagraphTemplate7 {
    left: 68%;
    width: 80%;
  }
  .button-divvvvvv {
    margin-top: 5%;
    width: 80%;
  }
  .linkleadai-pppppp {
    margin-left: 0;
    text-align: center;
  }
}
