.section-container {
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .imgg {
    width: 100%;
    height: 350px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .userImgg {
    position: absolute;
    top: 340px;
    left: 12%; 
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #ffffff; /* Adjust this color to match your background */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  }
  
  .UserHeadingg {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  
  .userInfoo {
    margin-left: 17%;
    margin-top: 1%;
    color: #3855B3;  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .userText {
    display: flex;
    flex-direction: column;
  }
  
  .buttonContainer {
    margin-left: auto;
  }
  
  
  .userDescription {
    color: #353535;  
    background-color: #28439D1A;
    margin-top: 3%;
    margin-left: 9%;
    width: 80%;
    height: 120px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
      
      
  }
  
  body {
    margin: 0;
    background-color: #f6f7fa;
  }
  
  .social-iconss {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
  }
  
  .social-iconss .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #28439D1A;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 15px;
    width: 40px;  /* Adjust as needed */
    height: 40px; /* Adjust as needed */
  }
  
  .social-iconss img {
    width: 30px;
    height: 30px;
  }
  
  .btn-div {
    margin-top: 3%;
    margin-left: 15%;
    text-align: center;
  }
  
  .b1 {
    background-color: #3855B3;
    width: 60%;
    height: 50px;
    border-radius: 50px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .b-icon {
    position:absolute;
    margin-right: 70%;
  }
  
  .text {
    display: inline-block;
    vertical-align: middle;
  }
  
  .linklead-p {
    margin-left: 42%;
    margin-top: 5%;
  }
  
  .choose-image-button {
    display: inline-block;
    background-color: #F7F8FA;
    color: #555;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
  }
  
  .image-preview {
    width: 200px; /* Specify your desired width */
    max-height: 200px; /* Specify your desired height */
    border-radius: 8px; /* Specify your desired border radius */
    object-fit: cover; /* Ensures the image maintains its aspect ratio while fitting in the box */
    cursor: pointer;
  }
  
  .cropper-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .cropper-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Ensure the container is wide enough */
    max-width: 500px; /* Limit the maximum width */
  }
  
  .cropper-container .reactEasyCrop_CropArea {
    max-height: 400px; /* Adjust the height to fit within the container */
  }
  
  .cropper-container .reactEasyCrop_CropArea {
    border: 1px solid #ccc; /* Add a border for better visibility */
  }
  
  @media only screen and (max-width: 768px) {
    .userImgg {
      top: 330px;
      left: 25%; 
    }
    .userInfoo {
      margin-left: 40%;
    }
    .userDescc {
      margin-left: 0;
      width: 100%;
    }
    .btn-div {
      margin-left: 30%; 
      margin-top: 5%;
    }
    .linklead-p {
      margin-left: 0;
      text-align: center;
    }
    .social-iconss{
      margin-top: 5%;
    }
  }
  