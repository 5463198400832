/* 
body {
    margin: 0;
    background-color: #f6f7fa;
  }
  
  .dashboard-container {
    display: flex;
  }
  
  .content-container {
    flex: 1;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    margin-left: 102px;
  }
  .header h1 {
    font-size: 22px;
  }
  .header1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 18px;
    margin-left: 65px;
  }
  
  .header1 h1 {
    font-size: 22px;
  }

  
  .buttons {
    margin-right: 7%;
    border-radius: 50px;
    display: flex;
  }
  .icon{
    margin-left: 53%;
  }
  
  .buttons .circular-button1 {
    align-items: center;
    border: 1px solid;
    border-radius: 100px;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 20px;
    min-height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 12px;
  }
  .buttons .circular-button2 {
    align-items: center;
    background-color: #dee5ec;
    border: 0;
    border-radius: 100px;
    color: #0A66C2;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 20px;
    min-height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 12px;
  }
  
  .buttons .circular-button3 {
    align-items: center;
    background-color: #35479b;
    border: 0;
    border-radius: 100px;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    line-height: 20px;
    min-height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 12px;
  }
  
  .buttons .circular-button1 span {
    margin-left: 8px;
  }
  .buttons .circular-button3 span {
    margin-left: 8px;
  }
  
  .cards-section {
    background-color: #f6f7fa;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
  }
  
  .left-cards {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  
  .card {
    margin-bottom: 15px;
  }

  .section-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 1070px;
    margin-left: 62px;
    height: 65vh;
  }
  .section-container1 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 1070px;
    margin-left: 62px;
    height: 12vh;
    margin-top: 1%;
  }
  .section-container2 {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 1070px;
    margin-left: 62px;
    height: 65vh;
    margin-top: 5px;
  } 

  .section-container2 {
    overflow: hidden;
    height: auto;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.9s ease-out, opacity 0.5s ease-out; 
  }
  
  .section-container2.open {
    max-height: 1000px;
    opacity: 1;
  }
  
  
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .section-button {
    display: flex;
    align-items: center;
    padding: 4px 14px;
    background-color:#35479b; 
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .section-button span {
    margin-right: 5px;
  }
  
  .section-paragraph {
    color: #626263;
    font-weight: 500;
  }

  .chart{
    padding-bottom: 5px;
  }

   */


   body {
    margin: 0;
    background-color: #f6f7fa;
  }

  
  .dashboard-container {
    display: flex;
  }
  
  .content-container {
    flex: 1;
  }
  .section-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .section-container1 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .section-container11 {
    background-color: #b3c3c7;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  
  .section-container2 {
    overflow: hidden;
    height: auto;
    max-height: 1000px;
    opacity: 1;
    transition: max-height 0.9s ease-out, opacity 0.3s ease-out; 
  }
  
  .section-container2.closed {
    max-height: 0; 
    opacity: 0;
  }
  

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section-header1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  .section-button {
    display: flex;
    align-items: center;
    padding: 4px 14px;
    background-color:#35479b; 
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .section-button span {
    margin-right: 5px;
}

.green-bg {
  background-color: rgba(0, 255, 0, 0.5);
}
.check-icon{
  color: #75aa52;
}


.card-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(183, 197, 195, 0.61);
  border-radius: 10px;
}

.radio-label {
  margin-bottom: 10px;
}

.radio-input {
  opacity: 0;
  cursor: pointer;
}

.radio-custom {
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #35479b;
  border-radius: 50%;
}

.radio-custom::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  background-color: #35479b;
  border-radius: 50%;
  opacity: 0;
}

.radio-input:checked ~ .radio-custom::after {
  opacity: 1;
}
